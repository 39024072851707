import createIconHTML from "../icon/create-icon-html";
import type { Reco } from "./events";

function createImageHTML(accessory: Reco) {
  return `
    <img
      src="${accessory.mobileImageLink}"
      sizes="(min-width: 1280px) 159px, (min-width: 768px) 154px, 139px"
      srcset="${accessory.desktopImageLink} 159px, ${accessory.tabletImageLink} 154px, ${accessory.mobileImageLink} 139px"
      alt="${accessory.imageAlt}"
      class="max-w-[139px] m:max-w-[154px] l:max-w-[159px] object-contain"
    /> 
  `;
}

function createRatingHTML(accessory: Reco) {
  const { rating } = accessory;

  if (rating === undefined) {
    return "";
  }

  return rating
    .map(
      (star) => `
        <span class="${
          ["full", "half"].includes(star) ? "text-ui-border-standardyellow" : ""
        }">
          ${createIconHTML(
            star === "full"
              ? "star-active"
              : star === "half"
              ? "star-half-alt-active"
              : "star",
          )}
        </span>
      `,
    )
    .join("");
}

export default function createAccessoryHTML(accessory: Reco) {
  return `
    <li class="list-item border border-ui-border-standard rounded-lg">
      <a
        href="${accessory.link}"
        class="inline-block h-full w-full p-3"
      >
        <div class="flex justify-center">
          ${createImageHTML(accessory)}
        </div>
        <div class="min-h-[63px] mt-4 text-body-small font-bold line-clamp-3">
          ${accessory.title}
        </div>
        <span class="min-h-[21px] flex gap-1 mt-2 text-body-small text-ti-disabled">
          ${createRatingHTML(accessory)}
        </span>
        <span class="block mt-5 text-heading-6 font-bold">
          ${accessory.price}
        </span>
        <span class="block min-h-[37px] text-body-small text-ti-additional2">
          ${accessory.unit}
        </span>
      </a>
    </li>
  `;
}
