import { isCoordInElement } from "../../utils/typescript/helpers";

export default function initializeCarousel(
  wrapper: HTMLElement,
  maxElements: number,
) {
  const leftButton = wrapper.querySelector(".rotate-left");
  const rightButton = wrapper.querySelector(".rotate-right");
  const listElement = wrapper.querySelector("ul");

  if (!leftButton || !rightButton || !listElement) {
    throw new Error("Carousel element could not be initialized");
  }

  const elements = Array.from(listElement.children);

  const firstPage = 1;
  const lastPage = Math.ceil(elements.length / maxElements);
  let page = firstPage;

  const redraw = () => {
    const pageStart = page * maxElements - (maxElements - 1);

    elements.forEach((e, i) => {
      const current = i + 1;

      if (current >= pageStart && current < pageStart + maxElements) {
        e.classList.replace("hidden", "list-item");
      } else {
        e.classList.replace("list-item", "hidden");
      }
    });

    // Set a small nasty delay,
    // to prevent triggering a click on the carousel item below the button,
    // when the clicked carousel button vanishes
    setTimeout(() => {
      if (page === firstPage) {
        leftButton.classList.replace("inline", "hidden");
      }

      if (page > firstPage) {
        leftButton.classList.replace("hidden", "inline");
      }

      if (page < lastPage) {
        rightButton.classList.replace("hidden", "inline");
      }

      if (page === lastPage) {
        rightButton.classList.replace("inline", "hidden");
      }
    }, 10);
  };

  redraw();

  function scrollLeft() {
    if (page > firstPage) {
      page--;

      redraw();
    }
  }

  function scrollRight() {
    if (page < lastPage) {
      page++;

      redraw();
    }
  }

  leftButton.addEventListener("click", scrollLeft);
  rightButton.addEventListener("click", scrollRight);

  let xStart = 0;

  function handleTouchStart(e: TouchEvent) {
    xStart = e.changedTouches[0]?.screenX ?? 0;
  }

  const handleTouchEnd = (e: TouchEvent) => {
    const [touch] = e.changedTouches;

    if (touch && isCoordInElement(listElement, touch.clientX, touch.clientY)) {
      // Threshhold for not scrolling with simple touches
      if (Math.abs(xStart - touch.screenX) > 20) {
        if (xStart > touch.screenX) {
          scrollRight();
        } else {
          scrollLeft();
        }
      }
    }
  };

  document.addEventListener("touchstart", handleTouchStart);
  document.addEventListener("touchend", handleTouchEnd);

  return () => {
    leftButton.removeEventListener("click", scrollLeft);
    rightButton.removeEventListener("click", scrollRight);

    document.removeEventListener("touchstart", handleTouchStart);
    document.removeEventListener("touchend", handleTouchEnd);
  };
}
