import type { AddToCartLayerEvents } from "./events";

export default function createImageHTML(
  article: AddToCartLayerEvents["pda.cart.add-to-cart"]["article"],
) {
  return `
    <img
      src="${article.mobileImageLink}"
      sizes="(min-width: 768px) 120px, 100px"
      srcset="${article.tabletImageLink} 120px, ${article.mobileImageLink} 100px"
      alt="${article.imageAlt}"
      class="max-w-[100px] m:max-w-[120px] object-contain"
    />
  `;
}
