/* eslint-disable no-inner-declarations */
import { isCoordInElement } from "../../utils/typescript/helpers";
import initializeCarousel from "../carousel/carousel";
import createAccessoryHTML from "./create-accessory-html";
import createImageHTML from "./create-image-html";
import { Reco } from "./events";

function getMaxElements() {
  if (window.innerWidth >= 1280) {
    return 3;
  }

  if (window.innerWidth >= 768) {
    return 4;
  }

  return 2;
}

function getElements() {
  const addToCartLayer =
    document.querySelector<HTMLDialogElement>("#add-to-cart-layer");

  if (!addToCartLayer) {
    return undefined;
  }

  const articleDetailsElement = addToCartLayer.querySelector<HTMLDivElement>(
    "#add-to-cart-layer-details",
  );
  const articleNumberElement = addToCartLayer.querySelector<HTMLSpanElement>(
    "#add-to-cart-layer-article-number",
  );
  const articleTitleElement = addToCartLayer.querySelector<HTMLDivElement>(
    "#add-to-cart-layer-title",
  );
  const articleImageElement = addToCartLayer.querySelector(
    "#add-to-cart-layer-image",
  );
  const quantityElement = addToCartLayer.querySelector<HTMLTableCellElement>(
    "#add-to-cart-layer-quantity",
  );
  const sumElement = addToCartLayer.querySelector<HTMLTableCellElement>(
    "#add-to-cart-layer-sum",
  );
  const accessoryWrapperElement = addToCartLayer.querySelector<HTMLDivElement>(
    "#add-to-cart-layer-accessory-wrapper",
  );
  const accessoryHeadlineElement = addToCartLayer.querySelector<HTMLDivElement>(
    "#add-to-cart-layer-accessory-headline",
  );
  const recoHeadlineElement = addToCartLayer.querySelector<HTMLDivElement>(
    "#add-to-cart-layer-reco-headline",
  );
  const accessoryElement = addToCartLayer.querySelector<HTMLUListElement>(
    "#add-to-cart-layer-accessory",
  );
  const closeButtons = addToCartLayer.querySelectorAll(".close");

  if (
    !articleDetailsElement ||
    !articleNumberElement ||
    !articleTitleElement ||
    !articleImageElement ||
    !quantityElement ||
    !sumElement ||
    !accessoryWrapperElement ||
    !accessoryHeadlineElement ||
    !recoHeadlineElement ||
    !accessoryElement
  ) {
    throw new Error("Add to cart layer not fully provided via HTML");
  }

  return {
    addToCartLayer,
    articleDetailsElement,
    articleNumberElement,
    articleTitleElement,
    articleImageElement,
    quantityElement,
    sumElement,
    accessoryWrapperElement,
    accessoryHeadlineElement,
    recoHeadlineElement,
    accessoryElement,
    closeButtons,
  };
}

document.addEventListener("DOMContentLoaded", () => {
  const elements = getElements();

  if (!elements) {
    return;
  }

  let originalOverflow = document.body.style.overflow;

  document.addEventListener("pda.cart.add-to-cart", (e) => {
    elements.articleNumberElement.innerText = e.detail.article.articleNumber;
    elements.articleTitleElement.innerText = e.detail.article.title;
    elements.articleImageElement.innerHTML = createImageHTML(e.detail.article);
    elements.quantityElement.innerText = `${e.detail.quantity}`;
    elements.sumElement.innerText = e.detail.sum;

    let unregisterDynamicHandlers: () => void = () => {};

    let accessoires: Reco[] = [];
    const hasAccessories = (e.detail.accessories?.length ?? 0) > 0;
    const hasReco = !hasAccessories && (e.detail.reco?.length ?? 0) > 0;

    if (e.detail.accessories && hasAccessories) {
      accessoires = e.detail.accessories;
    } else if (e.detail.reco && hasReco) {
      accessoires = e.detail.reco;
    }

    if (hasAccessories || hasReco) {
      if (hasAccessories) {
        elements.accessoryHeadlineElement.classList.replace("hidden", "inline");
      } else {
        elements.recoHeadlineElement.classList.replace("hidden", "inline");
      }

      let unregisterAccessoryHandlers: () => void = () => {};

      const initializeAccessory = () => {
        elements.accessoryElement.innerHTML = "";

        accessoires.forEach((accessory) => {
          elements.accessoryElement.innerHTML += createAccessoryHTML(accessory);
        });

        unregisterAccessoryHandlers = initializeCarousel(
          elements.accessoryWrapperElement,
          getMaxElements(),
        );
      };

      initializeAccessory();

      elements.articleDetailsElement.classList.add("pb-6");
      elements.accessoryWrapperElement.hidden = false;

      window.addEventListener("resize", initializeAccessory);

      unregisterDynamicHandlers = () => {
        window.removeEventListener("resize", initializeAccessory);

        unregisterAccessoryHandlers();
      };
    }

    elements.addToCartLayer.showModal();
    originalOverflow = document.body.style.overflow;
    document.body.style.overflow = "hidden";

    const handleBackdropClose = (e: MouseEvent) => {
      if (!isCoordInElement(elements.addToCartLayer, e.clientX, e.clientY)) {
        handleClose();
      }
    };

    const handleClose = () => {
      elements.addToCartLayer.close();
      document.body.style.overflow = originalOverflow;

      // We need to cleanup every eventListener,
      // due to re-registration of handlers when re-opening dialog with different parameters
      unregisterDynamicHandlers();

      elements.addToCartLayer.removeEventListener("click", handleBackdropClose);
      elements.closeButtons.forEach((closeButton) => {
        closeButton.removeEventListener("click", handleClose);
      });
    };

    elements.addToCartLayer.addEventListener("click", handleBackdropClose);
    elements.closeButtons.forEach((closeButton) => {
      closeButton.addEventListener("click", handleClose);
    });
  });
});
